<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Diego Alexander Villalba Cely                                 ###### -->
<!-- ###### @date: Octubre 2023                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="body-PB">
    <iframe title="Facturacion" width="100%" height="100%"
      src="https://app.powerbi.com/view?r=eyJrIjoiM2JkZTM5MGQtMTM2NS00ZTY5LTg0MTItNjY3N2E3YWI5N2QzIiwidCI6IjllMDQ0ZjE3LWM3M2MtNGU1ZC04NWViLTMyNDNlNTU1YjczOSIsImMiOjR9"
      frameborder="0" allowFullScreen="true"></iframe>
  </div>
</template>
         
         <!-- #################################################################################### -->
         <!-- ###### Sección de Scripts                                                     ###### -->
         <!-- #################################################################################### -->
<script>
export default {
  name: 'Devoluciones',
}
</script>
         
         <!-- #################################################################################### -->
         <!-- ###### Sección de styles                                                      ###### -->
         <!-- #################################################################################### -->
<style scoped>
.body-PB {
  height: 93% !important;
}
</style>